<template>
  <b-card title="المدرسين">
    <b-tabs>
    <b-tab active  @click="$router.push('/create-instructor')">
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/create-instructor"
            class="no-color"
          >
          <feather-icon icon="PlusIcon" />
          <span>إضافة مدرس </span>
          </a>
        </template>
<AddInstructor @add-instructor="getData"></AddInstructor>
      </b-tab>
      
      <b-tab  @click="$router.push('/instructors-list')"> 
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/instructors-list"
            class="no-color"
          >
          <feather-icon icon="HomeIcon" />
          <span>كل المدرسين </span>

          </a>
        </template>
        <router-view></router-view>
<!-- <InstructorList ref="allData"></InstructorList> -->
      </b-tab>
      
     
    </b-tabs>

  </b-card>
</template>

<script>

import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'



import InstructorList from './InstructorList.vue';
import AddInstructor from './AddInstructor.vue';
import { ref } from "@vue/composition-api";

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
   
    InstructorList,
    AddInstructor
},
setup() {
    const allData=ref()

  
    const getData=()=>{
      console.log("Eee")
    
      allData.value.refetchData()

           

    }
   return{
    getData,
  
    allData,
   

   }
   
   }
}
</script>
